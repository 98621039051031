(function($) {
    'use strict';

    $('.img-gallery').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true,
            navigateByImgClick: true
        }
    });

    var slider = $('.main-slider');
    var auto_play = slider.find('.slide:first video').length !== 0;

    slider.on('afterChange init', function(event, slick, currentSlide) {
        if($(this).find('.slick-active').find('video').length) {
            if(typeof currentSlide !== 'undefined') {
                slider.slick('slickPause');
            }
            var video = $(this).find('.slick-active video');
            video.get(0).play(0);
            video.on('ended', function() {
                slider.slick('slickNext').slick('slickPlay');
            });
        }
    }).on('beforeChange', function(event, slick, currentSlide, nextSlide) {
        var next = $(this).find('.slick-slide[data-slick-index="' + nextSlide + '"]');
        if(next.find('video').length) {
            var video = next.find('video');
            video.get(0).currentTime = 0;
        }
    }).slick({
        autoplay: auto_play,
        dots: true,
        arrows: false,
        speed: 300,
        autoplaySpeed: 5000
    });

    $('.youtube-video-link').on('click', function() {
        $(this).html('<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/' + $(this).data('video-id') + '?autoplay=1" frameborder="0" allowfullscreen></iframe>');
        return false;
    });

    $.fn.customValidate = function (rules) {
        var $form = $(this);
        var holder = $(this).closest('.form-holder');

        var showSuccess = function() {
            $form.hide();
            holder.find('.form-message').hide();
            holder.find('.form-message-success').show();
        };

        var showError = function() {
            holder.find('.form-message').hide();
            holder.find('.form-message-error').show();
        };

        $(this).validate({
             rules: rules,
             submitHandler: function (form, event) {
                 event.preventDefault();

                 $.ajax({
                     type: $form.attr('method'),
                     url: $form.attr('action'),
                     data: $form.serialize()
                 }).done(function (data) {
                     if (data.success === true) {
                         showSuccess();
                     } else {
                         showError();
                     }
                 }).fail(function () {
                     showError();
                 });
             },
             highlight: function (element) {
                 $(element).closest('.form-group').addClass('has-error');
             },
             unhighlight: function (element) {
                 $(element).closest('.form-group').removeClass('has-error');
             },
             errorElement: 'div',
             errorClass: 'help-block has-error',
             errorPlacement: function (error, element) {
                 if (element.attr('type') === 'checkbox' || element.attr('type') === 'radio') {
                     element.closest('.checkbox').append(error);
                 } else {
                     error.insertAfter(element);
                 }
             }
         });
    };
    
    $('.js-contact-form').customValidate({
        salutation: 'required',
        name: 'required',
        email: {
            required: true,
            email: true
        },
        subject: 'required',
        topic: 'required',
        text: 'required'
    });

    $('.js-contest-form').customValidate({
        email: {
            required: true,
            email: true
        },
        first_name: 'required',
        phone: 'required',
        last_name: 'required',
        street: 'required',
        street_number: 'required',
        zip: 'required',
        city: 'required',
        data_protection: 'required'
    });

    $('.js-promotion-form').customValidate({
        email: {
            required: true,
            email: true
        },
        first_name: 'required',
        phone: 'required',
        last_name: 'required',
        zip: 'required',
        data_protection: 'required'
    });

    window.setCookie = function(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = 'expires='+d.toUTCString();
        document.cookie = cname + '=' + cvalue + '; ' + expires;
    };

    window.getCookie = function(cname) {
        var name = cname + '=';
        var ca = document.cookie.split(';');
        for(var i=0; i<ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0)===' ') {c = c.substring(1);}
            if (c.indexOf(name) === 0) {return c.substring(name.length, c.length);}
        }
        return '';
    };

}(jQuery));
