(function($) {
    'use strict';

    $(document).ready(function () {
        if($('#calendar').length > 0) {
            var defaultCalendars = window.calendar_settings.default_calendars;

            var calendar = $('#calendar');
            var calendarIds = null;

            calendar.fullCalendar({
                locale: window.calendar_settings.locale,
                header: {
                    left: 'title',
                    center: '',
                    right: 'today prev,next'
                },
                titleFormat: 'D MMMM YYYY',
                columnFormat: 'ddd D.M',
                buttonText: {
                    today: 'Heute'
                },
                defaultView: 'basicWeek',
                nextDayThreshold: '00:00:00',
                timeFormat: 'HH:mm',
                displayEventEnd: true,
                height: 380,
                events: {}
            });

            $('.filter-select').click(function () {
                $(this).toggleClass('disabled');
                reloadCalendar();
            });

            var setCalendarIds = function () {
                calendarIds = [];
                $('.filter-select').each(function () {
                    if (!$(this).hasClass('disabled')) {
                        calendarIds.push($(this).data('calendar_id'));
                    }
                });
            };

            var buildEventTable = function (data) {
                var body = $('#event-tbody');
                body.html('');

                $.each(data, function (key, value) {
                    var checkDate = moment(value.start);
                    var now = moment().subtract(1, 'day');

                    if (value.end !== null) {
                        checkDate = moment(value.end);
                    }

                    if (checkDate < now) {
                        return true;
                    }

                    var row = $('<tr/>');

                    var time = '-';
                    if (value.allDay === false || value.allDay === 'false') {
                        time = moment(value.start).format('HH:mm');
                        if (value.end !== null) {
                            time += '&nbsp;- ' + moment(value.end).format('HH:mm');
                        }
                    }

                    var title = (value.title) ? value.title : '-';
                    if (value.link !== null) {
                        var target = '_blank';
                        if (value.link.substring(0, 1) === '/') {
                            target = '_self';
                        }
                        title = '<a href="' + value.link + '" target="' + target + '">' + title + '</a>';
                    }

                    var startDay = moment(value.start).format('DD.MM.YYYY');
                    var endDay = moment(value.end).format('DD.MM.YYYY');

                    if (value.allDay === true || value.allDay === 'true') {
                        if (moment(value.end).format('HH:mm') === '00:00') {
                            endDay = moment(value.end).subtract(1, 'day').format('DD.MM.YYYY');
                        }
                    }

                    if (value.end === null) {
                        endDay = startDay;
                    }

                    row.append($('<td/>', {html: '<div class="calendar-table-color" style="background:' + value.color + '"></div>'}));
                    if (startDay !== endDay) {
                        row.append($('<td/>', {html: startDay + '&nbsp;- ' + endDay}));
                    } else {
                        row.append($('<td/>', {text: moment(value.start).format('DD.MM.YYYY')}));
                    }
                    row.append($('<td/>', {html: time}));
                    row.append($('<td/>', {html: title}));
                    row.append($('<td/>', {text: (value.location) ? value.location : '-'}));


                    if(value.id && value.id !== 'undefined') {
                        row.append($('<td/>', {
                            'class': 'text-right',
                            html: '<a href="' + window.calendar_settings.download.webcal.replace("---id---", value.id) + '" title="' + window.calendar_settings.translate.webcal_download + '"><i class="fa fa-fw fa-calendar"></i></a>&nbsp;<a href="' + window.calendar_settings.download.normal.replace("---id---", value.id) + '" title="' + window.calendar_settings.translate.download + '"><i class="fa fa-fw fa-download"></i></a>'
                        }));
                    } else {
                        row.append($('<td/>'));
                    }
                    row.appendTo(body);
                });
            };

            var reloadCalendar = function () {
                setCalendarIds();
                window.setCookie('calendarIds', calendarIds, 365);

                $.ajax({
                    type: 'POST',
                    dataType: 'json',
                    url: window.calendar_settings.url,
                    data: {
                        calendarId: calendarIds
                    },
                    success: function (json) {
                        calendar.fullCalendar('removeEvents');
                        calendar.fullCalendar('addEventSource', json);
                        buildEventTable(json);
                    }
                });
            };

            var checkCookieData = function () {
                var calCookie = window.getCookie('calendarIds');
                if (calCookie === '') {
                    calCookie = defaultCalendars;
                }
                var calArray = calCookie.split(',');
                $('.filter-select').addClass('disabled');
                $.each(calArray, function (key, value) {
                    var element = $('#filter-select-' + value);
                    if (element) {
                        element.removeClass('disabled');
                    }
                });
            };

            checkCookieData();
            reloadCalendar();
        }
    });

}(jQuery));
